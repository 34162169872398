/* global chrome */

import React from "react";

import { Invitation } from "../Invitations/Invitation";

import {
  checkOS,
  checkBrowser,
  OS,
  supportedMobileOSes,
} from "../System/System";
import {
  LoginBeginResponse,
  RegisterBeginResponse,
} from "../API/XFA_DEVICE_API";

const chromeExtensionID = "ajobakkejbhbmiiodbcmobomohngjdhc";
const edgeExtensionID = "ikhelogcjkfnfccibbceakadlkbilhbg";
const firefoxExtensionID = "xfa-web-extension@xfa.tech";
const safariExtensionID =
  "tech.xfa.xfa-desktopapplication.SafariCheck.Extension (89ZG68UL25)";

export const getExtensionID = (navigator: Navigator) => {
  const browser = checkBrowser(navigator);
  switch (browser) {
    case "Chrome":
      return chromeExtensionID;
    case "Firefox":
      return firefoxExtensionID;
    case "Edge":
      return edgeExtensionID;
    case "Safari":
      return safariExtensionID;
    default:
      return chromeExtensionID;
  }
};

const sendMessage = (
  message: any,
  callback: (reply: any) => any,
  navigator: Navigator,
  b?: typeof chrome,
  w?: typeof window,
  timeout?: number
) => {
  if (b) {
    b.runtime.sendMessage(getExtensionID(navigator), message, (reply: any) => {
      callback(reply);
      if (b.runtime.lastError) {
        console.debug(b.runtime.lastError);
      }
    });
  } else if (w) {
    //try 5 times to check if complete page (incl. content script) has loaded, otherwise timeout
    for (let i = 0; i < 5; i++) {
      if (w.document.readyState === "complete") {
        break;
      } else {
        setTimeout(function () {}, 100);
      }
    }

    sendMessageToContentScript(message, callback, w, timeout);
  }
};

const sendMessageToContentScript = (
  message: any,
  callback: (reply: any) => any,
  w: typeof window,
  timeout?: number
) => {
  console.log("sending message to content script: " + JSON.stringify(message));
  // add a requestId to be sure that it only receives the right response
  const requestId = (Math.random() + 1).toString(36).substring(7); // Add a requestId

  // listen for response
  let evtFired = false;
  const listener = (evt: MessageEvent) => {
    if (
      evt.data?.response &&
      evt.data?.requestId &&
      evt.data.requestId === requestId
    ) {
      callback(evt.data.response);
      evtFired = true;
      w.removeEventListener("message", listener);
    }
  };
  w.addEventListener("message", listener);

  // set timeout to remove listener & throw error
  if (!timeout || timeout > 10000) {
    timeout = 10000;
  }
  setTimeout(function () {
    if (!evtFired) {
      w.removeEventListener("message", listener);
      console.log(
        "listening for response timed out, message: " + JSON.stringify(message)
      );
      callback(undefined);
    }
  }, timeout);

  // send message
  w.postMessage({ message: message, requestId: requestId, external: true });
};

export const openExtension = (browser: typeof chrome, navigator: Navigator) => {
  console.log("Opening extension on " + Date().toLocaleString());
  sendMessage(
    { message: "openExtension" },
    (reply: any) => {
      if (!reply) {
        // TODO display error
      }
    },
    navigator,
    browser,
    window
  );
};

export const sendInvitationsToExtension = (
  browser: typeof chrome,
  navigator: Navigator,
  invitations: Invitation[],
  silent: boolean,
  callback: (reply: any) => any
) => {
  sendMessage(
    {
      message: "invitation",
      silent: silent,
      invitations: invitations,
    },
    callback,
    navigator,
    browser,
    window,
    5000
  );
};

export const getCredentialID = (
  browser: typeof chrome,
  navigator: Navigator,
  callback: (reply: any) => any
) => {
  sendMessage(
    {
      message: "getCredentialId",
    },
    callback,
    navigator,
    browser,
    window,
    5000
  );
};

export const createCredential = (
  browser: typeof chrome,
  navigator: Navigator,
  callback: (reply: any) => any,
  body: RegisterBeginResponse
) => {
  sendMessage(
    {
      message: "createCredential",
      createCredentialsBody: body,
    },
    callback,
    navigator,
    browser,
    window,
    5000
  );
};

export const getCredential = (
  browser: typeof chrome,
  navigator: Navigator,
  callback: (reply: any) => any,
  body: LoginBeginResponse
) => {
  sendMessage(
    {
      message: "getCredential",
      getCredentialsBody: body,
    },
    callback,
    navigator,
    browser,
    window,
    5000
  );
};

export const checkDeviceAffiliatedWithOrganization = (
  browser: typeof chrome,
  navigator: Navigator,
  organizationId: string,
  callback: (reply: any) => any
) => {
  console.log(
    `Sending request to check if device is affiliated with organization:${organizationId}`
  );
  sendMessage(
    { message: "checkDeviceAffiliatedWithOrganization", organizationId },
    callback,
    navigator,
    browser,
    window
  );
};

export const sendCompleteTransactionToExtension = (
  browser: typeof chrome,
  navigator: Navigator,
  transactionId: string,
  callback: (reply: any) => any
) => {
  console.log(
    `Sending request to complete transaction:${transactionId} to extension`
  );
  sendMessage(
    { message: "completeTransaction", transactionId },
    callback,
    navigator,
    browser,
    window
  );
};

function useBrowserExtensionStatus(browser: any, navigator: Navigator) {
  const [installed, setInstalled] = React.useState<boolean | undefined>(
    undefined
  );
  const [checking, setChecking] = React.useState<boolean>(true);

  // System information
  const [os, setOS] = React.useState<undefined | OS>(undefined);

  React.useEffect(() => {
    setOS(checkOS(navigator));
  }, [navigator]);

  const checkExtensionInstalled = React.useCallback(() => {
    if (!os) {
      return;
    }

    if (supportedMobileOSes.includes(os)) {
      return;
    }

    setChecking(true);
    console.log(
      "Checking if extension installed on " + Date().toLocaleString()
    );

    // check if extension is installed
    try {
      sendMessage(
        { message: "installed" },
        (reply: any) => {
          console.log("reply: " + JSON.stringify(reply));
          setChecking(false);
          if (reply && reply.installed) {
            console.log("Setting to installed...");
            setInstalled(true);
          } else {
            console.log("Setting to not installed...");
            setInstalled(false);
          }
        },
        navigator,
        browser,
        window,
        1000 //too short and firefox will stop listening for response, too long and it will feel slow to detect if its not installed
      );
    } catch (e: any) {
      console.log(e);
      console.log("Setting to not installed because of error...");
      setInstalled(false);
      setChecking(false);
    }
  }, [os, browser, navigator]);

  const recheck = React.useCallback(() => {
    if (!os) {
      return;
    }

    if (supportedMobileOSes.includes(os)) {
      return;
    }

    console.log("rechecking...");
    checkExtensionInstalled();
  }, [os, checkExtensionInstalled]);

  // every refocus, check again
  const onFocusDesktop = React.useCallback(() => {
    recheck();
  }, [recheck]);
  React.useEffect(() => {
    if (!os) {
      return;
    }

    if (supportedMobileOSes.includes(os)) {
      return;
    }

    window.addEventListener("focus", onFocusDesktop);
    return () => {
      window.removeEventListener("focus", onFocusDesktop);
    };
  }, [os, onFocusDesktop]);

  React.useEffect(() => {
    if (!os) {
      return;
    }

    if (supportedMobileOSes.includes(os)) {
      setInstalled(false);
      return;
    }

    checkExtensionInstalled();
  }, [os, checkExtensionInstalled]);

  return { installed, checking, recheck };
}

export default useBrowserExtensionStatus;
